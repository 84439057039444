import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FooterComponent } from "../../components/footer";
import { HeadexComponent } from "../../components/header/headex";
import HowitWorks4 from "../../assets/img/hotitworks4.png";
import { HowitworksIcon } from "../../components/icons/howitworks";
import Howitworks1 from "../../assets/img/crypto-gif.gif";
import Howitworks2 from "../../assets/img/assets-gif.gif";
import Howitworks3 from "../../assets/svg/hotitworks3.svg";
import AppImage from "../../assets/img/app-face.png";
import Pixalo from "../../assets/svg/pixalo.svg";
import { GetStartedComponent } from "../../components/getstarted";
import MediaFeature from "../../components/meadiafeature/mediafeature";
import { ComingSoonComponent } from "../../components/comingsoon";
import { FrequentlyAskedComponent } from "../../components/frequentlyasked";
import { Testimonials } from "../../components/testimonials";
import "./style.scss";
import { orgName } from "../../helpers/brand";

export default function ADSLandingPage() {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="landingpage-2">
      <div className="landingpage-00">
        <Helmet>
          <title>{orgName} | Track All Your Assets In One Place. Plan Your Estate</title>
          <meta name="robots" content="noindex" />
          <meta
            name="description"
            content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
          />
          <meta
            property="og:title"
            content={`${orgName} | Track All Your Assets In One Place. Plan Your Estate`}
          />
          <meta
            property="og:description"
            content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
          />
          <meta property="og:url" content="https://www.getcova.com/" />
        </Helmet>
        <div className="headerholder">
          <HeadexComponent />
        </div>
        <div className="familybackground">
          <div data-aos="fade-up" data-aos-duration="1000">
            <div className="showmesomethings">
              <h1 className="organiset">
                Securely track <span>all</span> your assets in one place
              </h1>
              <br />
              <p className="organisec">
                Organize cryptocurrencies, bank accounts, stocks, investments and safely transfer
                your information to your beneficiaries in case of eventualities.
              </p>
              <br />
              <Link
                to="/signup"
                className="getstarted"
                style={{ height: "auto", textDecoration: "none", width: "140px" }}
              >
                Get started Free
              </Link>
            </div>
            <div className="sided1">
              <img src={AppImage} alt="" className="phone-image" />
            </div>
          </div>
        </div>

        <div id="how-it-works" className="how-it-works">
          <div className="beg1" data-aos="fade-up" data-aos-duration="1000">
            <div className="journer">
              <p className="">
                {" "}
                The journey to planning your inheritance starts with tracking your assets
              </p>
            </div>
            <div className="hereishow">
              <p className="hereishow-text">
                <span className="icon001">
                  <HowitworksIcon />
                </span>
                <span className="text44">here’s how it works</span>
              </p>
            </div>
          </div>

          <div className="image-how-it ">
            <div className="image-how-it-img-div left-img6 dol-4">
              <div className="cf3">
                <img
                  className="top"
                  src={Howitworks1}
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
            <div className="image-how-it-info" data-aos="fade-up" data-aos-duration="1000">
              <p className="image-how-it-info-1">
                Connect & track your cryptocurrencies and wallets
              </p>
              <p className="image-how-it-info-2">
                Track over 25 crypto-exchanges including Binance, Coinbase, Trust Wallets, and over
                8,000 crypto-tickers. Connect your wallets, cryptocurrencies and NFTs, get their
                balances in real-time and easily track their growth with {orgName}.
              </p>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="image-how-it-info" data-aos="fade-up" data-aos-duration="1000">
              <p className="image-how-it-info-1">
                Track your bank and investments accounts balances
              </p>
              <p className="image-how-it-info-2">
                {orgName} supports over 11,000 banks and financial institutions in the United States,
                Canada and Nigeria, including Bank of America, Wells Fargo, Wealthfront. See all
                your investments in one place. {orgName} uses industry-leading aggregation technology to
                give you access to your bank, retirement, and investment accounts.
              </p>
            </div>
            <div className="cf3 image-how-it-img-div right-img6">
              <img src={Howitworks2} alt="" data-aos="fade-up" data-aos-duration="1000" />
            </div>
          </div>
          <div className="image-how-it">
            <div className="image-how-it-img-div left-img6">
              <img src={Howitworks3} alt="" data-aos="fade-up" data-aos-duration="1000" />
            </div>
            <div className="image-how-it-info" data-aos="fade-up" data-aos-duration="1000">
              <p className="image-how-it-info-1">
                Add your traditional assets, create your digital vault.
              </p>
              <p className="image-how-it-info-2">
                Add traditional assets like real estate properties, cars, etc. Add their values and
                upload vital supporting documents. {orgName} is fully secured.{" "}
              </p>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="image-how-it-info" data-aos="fade-up" data-aos-duration="1000">
              <p className="image-how-it-info-1">
                Safely transfer your portfolio to your chosen beneficiaries.{" "}
              </p>
              <p className="image-how-it-info-2">
                Decide who receives access to your assets and safely transfer this information to
                them in the event of an unforeseen emergency.{" "}
              </p>
            </div>
            <div className="image-how-it-img-div right-img6">
              <img src={HowitWorks4} alt="" data-aos="fade-up" data-aos-duration="1000" />
            </div>
          </div>
        </div>

        <div id="secure-assets" className="secure-assets">
          <div className="secure-assets-width" data-aos="fade-up" data-aos-duration="1000">
            <p className="secure-assets-t1">Secure Wealth Tracking &amp; Portfolio Management.</p>
            <p className="secure-assets-t2">
              {orgName} uses end-to-end encryption to keep your data secure. All information is stored
              using AES-256 encryption. Our internal processes ensure no one at {orgName} gets to see
              your portfolio data.
            </p>
            <p className="secure-assets-t2">
              We use bank-grade security, working with the world’s best financial data partners to
              securely access and manage your financial data. We understand your store and track
              your sensitive data with {orgName}.
            </p>
            <Link to="/security" className="learn-more">
              Learn more about how we secure your data
            </Link>
          </div>
          <div className="pixalo">
            <img width="770" height="435" src={Pixalo} alt="" />
          </div>
        </div>

        <MediaFeature />
        <Testimonials />
        <ComingSoonComponent />
        <FrequentlyAskedComponent />
        <GetStartedComponent theme="blue" />
        <FooterComponent />
      </div>
    </div>
  );
}
