import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Burgermenu2Icon } from "../icons/burgermenu2";
import { CovaLogo } from "../icons/covelogo";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { showLifecheckLogo } from "../../helpers/brand";

const HeaderMinimal = () => {
  const [hidediv, sethidediv] = useState(true);

  return (
    <div className="headercomp">
      <div className="desktop ">
        <div>
          <NavLink to="/" className="covalogo">
            {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={100} />}
          </NavLink>

          <div className="alllinks">
            <div className="textsd">Already have an account with us?</div>

            <a
              className="getstarted signup"
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_SIGN_LINK}
            >
              Sign In
            </a>
          </div>
        </div>
      </div>

      <div className="mobile ">
        <div className="inner-top">
          <div className="v-222">
            <NavLink to="/" className="covalogo">
              <CovaLogo />
            </NavLink>
            <div>
              <span
                onClick={() => {
                  sethidediv(!hidediv);
                }}
              >
                <Burgermenu2Icon />
              </span>
            </div>
          </div>

          <div style={{ display: hidediv ? "none" : "flex" }} className="layer2">
            <div className="textsd">Already have an account with us?</div>

            <a
              className="getstarted signup"
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_SIGN_LINK}
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMinimal;
