import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Burgermenu2Icon } from "../icons/burgermenu2";
import { CovaLogo } from "../icons/covelogo";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as Thunder } from "../../assets/svg/thunder.svg";
import "./header.scss";
import { orgName, showLifecheckLogo, signinLink, supportEmail } from "../../helpers/brand";

export const HeadexComponent = () => {
  const [hidediv, sethidediv] = useState(true);
  const [showAbout, setShowAbout] = useState<boolean>(false);
  const navigate = useNavigate();
  const routes = [
    {
      name: "How it works",
      route: "/how-it-works",
    },
    {
      name: "Pricing",
      route: "/pricing",
    },
    {
      name: "Security",
      route: "/security",
    },
    {
      name: `Why ${orgName}?`,
      route: "/why-cova",
    },
    // {
    //   name: (
    //     <div>
    //       {orgName} for Business <span className="beta-flag blue">BETA</span>
    //     </div>
    //   ),
    //   route: "/business",
    // },
  ];

  return (
    <div className="headercomp">
      <div className="desktop ">
        <div>
          <NavLink to="/" className="covalogo">
            {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={100} />}
          </NavLink>
          <div className="alllinks">
            <div
              className="linksd about"
              onClick={() => {
                setShowAbout(!showAbout);
              }}
            >
              About {orgName} <ArrowDown className={`arrow-d  ${showAbout ? "rotate213" : ""}`} />
              <div className={`about-content ${showAbout ? "show-a" : ""}`}>
                <div
                  className="click"
                  onClick={() => {
                    navigate("/why-cova");
                  }}
                >
                  <NavLink
                    className={({ isActive }) =>
                      ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
                    }
                    to="/why-cova"
                  >
                    Why {orgName}?
                  </NavLink>
                </div>

                <div
                  className="click"
                  onClick={() => {
                    navigate("/how-it-works");
                  }}
                >
                  <NavLink
                    className={({ isActive }) =>
                      ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
                    }
                    to="/how-it-works"
                  >
                    How it works
                  </NavLink>
                </div>

                <div
                  className="click"
                  onClick={() => {
                    navigate("/security");
                  }}
                >
                  <NavLink
                    className={({ isActive }) =>
                      ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
                    }
                    to="/security"
                  >
                    Security
                  </NavLink>
                </div>

                <a className="urls-links" href={`mailto:${supportEmail}`}>
                  <div className="click">Support</div>
                </a>
              </div>
            </div>

            <a href="https://getcova.com/blog" className="linksd" target="_blank" rel="noreferrer">
              Blog
            </a>

            <NavLink
              className={({ isActive }) =>
                ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
              }
              to="/pricing"
            >
              Pricing
            </NavLink>

            {/* <NavLink
              className={({ isActive }) =>
                ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
              }
              to="/business"
            >
              {orgName} for Business <span className="beta-flag blue">Beta</span>
            </NavLink> */}

            <a className="linksd signin" href={signinLink} target="_blank" rel="noreferrer">
              Sign In
            </a>

            <a href={signinLink} target="_blank" rel="noreferrer">
              <div className="download-app-btn click">
                <Thunder /> Download the app
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="mobile ">
        <div className="inner-top">
          <div className="v-222">
            <NavLink to="/" className="covalogo">
              {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={100} />}
            </NavLink>
            <div>
              <span
                onClick={() => {
                  sethidediv(!hidediv);
                }}
              >
                <Burgermenu2Icon />
              </span>
            </div>
          </div>

          <div style={{ display: hidediv ? "none" : "flex" }} className="layer2">
            <a href="https://getcova.com/blog" className="linksd" target="_blank" rel="noreferrer">
              Blog
            </a>

            {routes.map((item, index) => (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  ["linksd", isActive ? "selected-nav-link" : null].filter(Boolean).join(" ")
                }
                to={item.route}
              >
                {item.name}
              </NavLink>
            ))}

            <a className="linksd signin" href={signinLink} target="_blank" rel="noreferrer">
              Sign In
            </a>
            <a href={signinLink} target="_blank" rel="noreferrer">
              <div className="download-app-btn click">
                <Thunder /> Download the app
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
