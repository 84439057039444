import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import Modal from "react-modal";
import { FooterComponent } from "../../components/footer";
import { HeadexComponent } from "../../components/header/headex";
import How1 from "../../assets/img/how1.png";
import How2 from "../../assets/img/how2.png";
import How3 from "../../assets/img/how3.png";
import How4 from "../../assets/svg/how4.svg";
import { ReactComponent as SmallBg } from "../../assets/svg/how-small-bg.svg";
import { ReactComponent as Ellipse } from "../../assets/svg/how-it-works-ellipse.svg";
import { ReactComponent as PlayButton } from "../../assets/svg/play-video-button.svg";
import ManJumping from "../../assets/img/man-jumping.png";
import { GetStartedComponent } from "../../components/getstarted";
import { Helmet } from "react-helmet";
import "./howitworks.scss";
import { orgName } from "../../helpers/brand";

const modalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    overflow: "hidden",
    transform: "translate(-50%, -40%)",
    zIndex: "305 !important",
  },
};

export default function HowItWorks() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    const body: any = document.querySelector("body");
    body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  return (
    <div>
      <Helmet>
        <title>How {orgName} Works</title>
        <meta
          name="description"
          content={`${orgName} is the single source of truth for all your investment. The borderless assets tracking tool to track everything that you own in one place.`}
        />
        <meta property="og:title" content={`How ${orgName} Works`} />
        <meta
          property="og:description"
          content={`${orgName} is the single source of truth for all your investment. The borderless assets tracking tool to track everything that you own in one place.`}
        />
      </Helmet>

      <HeadexComponent />

      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <div className="player-wrapper">
          <ReactPlayer playing width="100%" height="100%" url="https://youtu.be/VYSFtxb635Y" />{" "}
        </div>
      </Modal>
      <div className="how-it-works">
        <div className="how-hero">
          <div className="how-hero-wrapper" data-aos="fade-up" data-aos-duration="1000">
            <div className="how-hero-text">
              <div className="how-hero-title">
                <span>Automatically</span> keep track of all your assets with {orgName}
              </div>
              <div className="how-hero-desc">
                Say goodbye to manual portfolio tracking and managing our assets with paper and
                excel sheets. Never worry about the fear of "what happens to my assets in the event
                of an eventuality."
              </div>
            </div>
            <div className="how-it-works-video">
              <div className="how-it-works-wrapper">
                <div className="video-bg">
                  <PlayButton
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className={`play-button ${showModal ? "change-index" : ""}`}
                  />
                  <img
                    src={ManJumping}
                    className={`man-jumping ${showModal ? "change-index" : ""}`}
                    alt="man-jumping"
                  />
                  <div className={`video-desc ${showModal ? "change-index" : ""}`}>
                    Watch how {orgName} works
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how-divider" data-aos="fade-up" data-aos-duration="1000">
          4 steps to managing your portfolio with {orgName}
          <span>
            <SmallBg />
          </span>
        </div>

        <div className="how-main">
          <div className="image-how-it ">
            <div
              className="image-how-it-img-div left-img6 dol-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={How1} alt="" />
            </div>
            <div className="how-text-content" data-aos="fade-up" data-aos-duration="1000">
              <div className="number-how">
                <span>1</span> <Ellipse className="how-ellipse" />
              </div>
              <div className="title-how">Create an account.</div>
              <div className="desc-how">
                Create a {orgName} account, choose a plan and enjoy free one-month access to {orgName}. No
                need to enter your credit card details. You will be good to go in minutes.
              </div>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="how-text-content" data-aos="fade-up" data-aos-duration="1000">
              <div className="number-how">
                <span>2</span> <Ellipse className="how-ellipse" />
              </div>
              <div className="title-how">
                Link your accounts, apps, assets, add traditional holdings.
              </div>
              <div className="desc-how">
                Connect your bank accounts, Brokerages, Crypto-exchange accounts, stock tickers, and
                more. Manually add traditional assets and documents related to each asset. {orgName}
                automatically syncs your balances and holdings across all your accounts.
              </div>
            </div>
            <div
              className="cf3 image-how-it-img-div right-img6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={How2} alt="" />
            </div>
          </div>

          <div className="image-how-it">
            <div
              className="image-how-it-img-div left-img6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={How3} alt="" />
            </div>
            <div className="how-text-content" data-aos="fade-up" data-aos-duration="1000">
              <div className="number-how">
                <span>3</span> <Ellipse className="how-ellipse" />
              </div>
              <div className="title-how">Setup your beneficiaries & life-checker settings.</div>
              <div className="desc-how">
                Add a beneficiary and trusted angel to your portfolio. Add your life-checker checker
                settings. {orgName} will use your settings to check you when we become inactive on the
                app. {orgName} automatically notifies your beneficiaries following your life-checker
                settings.
              </div>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="how-text-content" data-aos="fade-up" data-aos-duration="1000">
              <div className="number-how">
                <span>4</span> <Ellipse className="how-ellipse" />
              </div>
              <div className="title-how">Share your financial portfolio data in real-time</div>
              <div className="desc-how">
                Securely collaborate with your spouse, attorney, advisors, and planners in
                real-time, share your growing portfolio data with people who matter to you. Make
                smart investment decisions together. Share your net worth and asset data for loan
                and credit purposes.
              </div>
            </div>
            <div
              className="cf3 image-how-it-img-div right-img6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={How4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <GetStartedComponent theme="blue" />
      <FooterComponent />
    </div>
  );
}
