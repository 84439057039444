import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as CovaLogo } from "../../assets/svg/footer-logo.svg";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { InstagramIcon } from "../../components/icons/instagram";
import { TwittterIcon } from "../../components/icons/twittter";
import { Facebookicon } from "../../components/icons/facebook";
import { orgName, signupLink, supportEmail } from "../../helpers/brand";
import "./footer.scss";

const isProd = process.env.NODE_ENV === "production";

export const FooterComponent = ({ visitor }: any) => {
  return (
    <div className="covafooter-container">
      <div id="covafooter-1" className="covafooter-1">
        <Link to="/" className="logodiv">
          {isProd ? () => <CovaLogo /> : <LifecheckLogo width={180} height={100} />}
        </Link>
        <div className="urls">
          <p className="urls-header">Company</p>
          {visitor ? (
            <>
              <Link className="urls-links" to="/why-cova">
                About {orgName}
              </Link>

              <Link className="urls-links" to="/pricing">
                Pricing
              </Link>

              {/* <Link className="urls-links" to="/business">
                {orgName} for Business <span className="beta-flag blue">Beta</span>
              </Link> */}
            </>
          ) : (
            <>
              <Link className="urls-links" to="/how-it-works">
                How {orgName} Works
              </Link>
              <a href={`${process.env.REACT_APP_HOME}security`} className="urls-links">
                Our Commitment to Security
              </a>
            </>
          )}
        </div>
        <div className="urls">
          <p className="urls-header">Resources</p>
          <a href="https://getcova.com/blog" className="urls-links">
            Blog
          </a>
          <Link to="#" className="urls-links">
            Features
          </Link>
          <HashLink to="/#media-feature" smooth className="urls-links">
            Press
          </HashLink>
          <HashLink to="/#faqs" smooth className="urls-links">
            FAQs
          </HashLink>
        </div>
        <div className="urls">
          <p className="urls-header">Get in Touch</p>
          <div className="urls-links">1629 K St Suite 300, N.W. Washington D.C. 20006</div>
          <a className="urls-links" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
          <a className="urls-links" href="tel:+16677714746">
            +1(667) 771-4746
          </a>
          <div className="url-socials">
            <a
              target="_blank"
              rel="noreferrer"
              className="url-socials-t"
              href="https://twitter.com/get_cova"
            >
              <TwittterIcon />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="url-socials-t"
              href="https://web.facebook.com/getcova"
            >
              <Facebookicon />
            </a>
            <a className="url-socials-t" href="https://www.instagram.com/cova.hq">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>

      <div id="covafooter-2" className="covafooter-2">
        <div>
          <div className="covafooter-2-1">
            <p className="copyright-theme">
              © {new Date()?.getFullYear() ?? "2021"} {orgName}. All rights reserved
            </p>
          </div>
          <div className="covafooter-2-2">
            <Link to="/privacy" className="links-3">
              Privacy Policy
            </Link>
            <Link to="/terms-and-condition" className="links-3">
              Terms and Conditions
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile44">
        <a href={signupLink} className="getstarted22">
          Get started for free
        </a>
      </div>
    </div>
  );
};
