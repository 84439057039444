import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Authheader } from "../auth/authheader";
import { ReactComponent as CheckIcon } from "../../assets/svg/check-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/cancel.svg";
import { ReactComponent as StarsBlack } from "../../assets/svg/stars-black.svg";
import { Helmet } from "react-helmet";
import useReadLocalStorage from "../../hooks/useReadLocalStorage";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import "./../../pages/auth/auth.scss";
import "../pricing/pricing.scss";
import { ReactComponent as Vector } from "../../assets/svg/onboarding-vector.svg";
import { ReactComponent as Vector2 } from "../../assets/svg/onboarding-vector-2.svg";
import queryString from "query-string";
import { VerifyAccountResponse } from "../verify-email";
import { toast } from "react-toastify";
import { orgName } from "../../helpers/brand";

export default function ChosePlan() {
  const [yearlySelected, setYearlySelected] = useState(true);
  const [couponDetails, setCouponDetails] = useState<string>("");
  const firstName = useReadLocalStorage("firstName");
  const [paidExpanded, setPaidExpanded] = useState(false);
  const [freeExpanded, setFreeExpanded] = useState(false);
  const location = useLocation();
  let parseddata = queryString.parse(location.search);
  const [userdata, setUserdata] = useState<VerifyAccountResponse | null>(null);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }

    if (parseddata?.coupon) {
      setCouponDetails(parseddata?.coupon as string);
    }

    const getUserdata = localStorage.getItem("userdata");

    if (getUserdata) {
      setUserdata(JSON.parse(getUserdata));
    }

    //eslint-disable-next-line
  }, []);

  const goTo = (href: string) => {
    window.location.href = href;
  };

  const redirect = () => {
    if (userdata?.accessToken) {
      goTo(`${process.env.REACT_APP_SIGN_LINK}signup?token=${userdata.accessToken}`);
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="authpage">
      <Helmet>
        <title>Select Plan</title>
        <meta name="description" content="Select the plan that best suits your needs." />
        <meta property="og:title" content="Select Plan" />
        <meta property="og:description" content="Select the plan that best suits your needs." />
      </Helmet>

      <Authheader noClick />
      <div className="pricing-page-container">
        <Vector className="o-vect top" />
        <Vector2 className="o-vect bottom" />

        <div className="pricing-hero checkout">
          <div className="pricing-hero-wrapper">
            <div className="pricing-hero-t1">Hi{firstName ? ` ${firstName}` : ""},</div>

            <div className="pricing-hero-t2">Select the plan that best suits your needs.</div>

            <div className="pricing-hero-t3">
              Most users choose Premium for a fully automated experience and complete access to all
              features.
            </div>
          </div>

          <div className="pricing-main">
            <div className="pricing-plans">
              <div className="pricing-info">
                <div className="pricing-switch">
                  <div
                    className={`monthlyp ${!yearlySelected ? "selected" : ""}`}
                    onClick={() => {
                      setYearlySelected(false);
                    }}
                  >
                    Monthly
                  </div>
                  <div
                    className={`yearlyp ${yearlySelected ? "selected" : ""}`}
                    onClick={() => {
                      setYearlySelected(true);
                    }}
                  >
                    Yearly (save 20%)
                  </div>
                </div>

                <div className="topp">
                  <div className="t-left">{orgName} Premium</div>

                  <div className="t-right">{yearlySelected ? "$100" : "$10"}</div>
                </div>

                <Link
                  to={`/card-setup/?plan=${yearlySelected ? "year" : "month"}${
                    couponDetails ? `&code=${couponDetails}` : ""
                  }`}
                >
                  <div className="pricing-button">Start your 14 days free trial</div>
                </Link>

                <div className="middlep">
                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Sync balance directly from your finance and investment Apps
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Real-time tracking of over 150,000 global stocks.
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Track over 8000 crypto tickers, wallets, and exchanges in real-time.
                    </div>
                  </div>

                  <div className={`expand-group ${paidExpanded ? "" : "e-g-hide"}`}>
                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Connect over 11,000 banks and brokerage accounts
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Automated Net worth Tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Automated liabilities tracking; credit cards, loans & more
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Manage portfolio beneficiaries; create a deadman’s switch
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Unlimited storage space for vital documents
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Monthly reports on portfolio and asset history.
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Data is live and current.</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setPaidExpanded(!paidExpanded);
                    }}
                    className="expand-card click"
                  >
                    View {paidExpanded ? "Less" : "All"}{" "}
                    <ArrowDown
                      className={`arrow-d color-white  ${paidExpanded ? "rotate213" : ""}`}
                    />
                  </div>
                </div>
              </div>

              <div className="pricing-info basic">
                <div className="premium-access">
                  <StarsBlack /> <span>Free Premium access for 14 days</span>
                </div>

                <div className="topp">
                  <div className="t-left">{orgName} Free Plan</div>

                  <div className="t-right">$0</div>
                </div>

                <div onClick={redirect} className="pricing-button basic">
                  Continue on Free Plan
                </div>
                <div className="b-text">*no credit card required</div>

                <div className="middlep">
                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">Track Your Net Worth </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Manual assets tracking; no real-time updates.
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Manual liabilities tracking; credit cards, loans, etc.
                    </div>
                  </div>

                  <div className={`expand-group ${freeExpanded ? "" : "e-g-hide"}`}>
                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Assign a beneficiary to your portfolio</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">500MB storage space for vital documents</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Manage portfolio beneficiaries; create a deadman’s switch
                      </div>
                    </div>

                    <div className="m-p-divider" />

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No cryptocurrency exchange/wallet tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No real-time stock tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No banks or brokerage accounts tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">Cannot connect finance & investments apps </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">Data is not in real-time</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setFreeExpanded(!freeExpanded);
                    }}
                    className="expand-card text-black click"
                  >
                    View {freeExpanded ? "Less" : "All"}{" "}
                    <ArrowDown className={`arrow-d ${freeExpanded ? "rotate213" : ""}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export type Plan = {
  active: boolean;
  amount: number;
  code: string;
  currency: Currency;
  id: string;
  interval: string;
  partner: string;
};

export interface Currency {
  code: string;
  symbol: string;
  name: string;
  base?: number;
}
