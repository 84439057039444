import { useRef } from "react";
import BlogOne from "../../assets/img/blog-one.png";
import BlogTwo from "../../assets/img/blog-two.png";
import BlogThree from "../../assets/img/blog-three.png";
import BlogFour from "../../assets/img/blog-four.png";
import BlogFive from "../../assets/img/blog-five.png";
import BlogSix from "../../assets/img/blog-six.png";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as ScrollLeft } from "../../assets/svg/scroll-left-larger.svg";
import { ReactComponent as ScrollRight } from "../../assets/svg/scroll-right-larger.svg";
import "./blog-posts.scss";
import { orgName } from "../../helpers/brand";

const BlogPosts = () => {
  const scrollRef: any = useRef();

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      scrollRef.current.scrollLeft -= 300;
    } else {
      scrollRef.current.scrollLeft += 300;
    }
  };

  const posts = [
    {
      title_one: "How to add",
      title_two: "How to add Crypto to my Estate Plan or Will",
      desc_one: "Crypto to my Estate Plan or Will",
      desc_two:
        "Leaving cryptocurrency to your loved ones requires more planning and effort than traditional assets. A way to do this is to provide access with a simplified process for your beneficiaries and ensure that they inherit your coins.",
      image: BlogOne,
      hashtags: ["Crypto", "Real Estate"],
      url: "https://blog.getcova.com/blog/add-crypto-to-my-estate-plan/",
      className: "one",
    },
    {
      title_one: "How To Keep",
      title_two: "How To Keep Track of Your Net Worth in Real Time",
      desc_one: "Track of Your Net Worth in Real Time",
      desc_two:
        "We've all heard the question, “What's your net worth.” either from a financial advisor or a financial institution or in general conversations. So let's dive into understanding net worth and how to track it, increase it, and use it best.",
      image: BlogTwo,
      hashtags: ["Finance"],
      url: "https://blog.getcova.com/blog/keep-track-of-your-net-worth-in-real-time/",
      className: "two",
    },
    {
      title_one: "Digital Estate",
      title_two: "Digital Estate Planning for Beginners",
      desc_one: "Planning for Beginners",
      desc_two:
        "Estate planning is the process of determining how a person's assets will be transferred or inherited in case of  death or incapacity. This is typically done in collaboration with one's financial and legal advisors.",
      image: BlogThree,
      hashtags: ["Finance"],
      url: "https://blog.getcova.com/blog/how-to-create-a-digital-estate-plan/",
      className: "three",
    },
    {
      title_one: "5 Ways To",
      title_two: "5 Ways To Increase Your Net worth?",
      desc_one: "Increase Your Net worth?",
      desc_two:
        "The goal of each and every one of us is to increase our net worth as much as possible. We put up these four simple steps that you can take to increase your net worth.",
      image: BlogFour,
      hashtags: ["Finances"],
      url: "https://blog.getcova.com/blog/4-ways-to-increase-your-net-worth/",
      className: "four",
    },
    {
      title_one: "Five reasons",
      title_two: "Five Reasons You Should Keep Your Eyes on Your Net Worth",
      desc_one: "You Should Keep Your Eyes on Your Net Worth",
      desc_two:
        "Your net worth is the single most important financial metric you should track. While your earnings, investments, and other metrics are great",
      image: BlogFive,
      hashtags: ["Finances"],
      url: "https://blog.getcova.com/blog/five-reasons-you-should-keep-your-eyes-on-your-net-worth/",
      className: "five",
    },
    {
      title_one: "What happens",
      title_two: "What happens to your Crypto assets if you get hit by a bus today?",
      desc_one: "To Your Crypto Assets if You Get Hit By A Bus Today?",
      desc_two:
        "Conversations around death are tough to have as most people, especially young people, find them very uncomfortable. ",
      image: BlogSix,
      hashtags: ["Finance", "Real Estate"],
      url: "https://blog.getcova.com/blog/what-happens-to-your-cryptocurrency-if-you-get-hit-by-a-bus-today/",
      className: "six",
    },
  ];
  return (
    <div className="blog-posts">
      <div className="b-p-wrapper">
        <div className="title911" data-aos="fade-up" data-aos-duration="1000">
          <div className="t91-one">Learn with {orgName} </div>
          <div className="t91-two">Our Blog Posts</div>
        </div>

        <div className="b-p-ctrl left click no-select">
          <ScrollLeft
            onClick={() => {
              handleScroll("left");
            }}
          />
        </div>

        <div className="b-p-ctrl right click no-select">
          <ScrollRight
            onClick={() => {
              handleScroll("");
            }}
          />
        </div>

        <div className="b-p-main" ref={scrollRef}>
          {posts.map((item, index) => (
            <div className="b-p-item" key={index}>
              <div className={`b-top ${item.className}`}>
                <div className="b-t-left">
                  <div className="l-title">{item.title_one}</div>

                  <div className="l-desc">{item.desc_one}</div>
                </div>

                <div className="b-t-right">
                  <img src={item.image} alt="blog cover" />
                </div>
              </div>

              <div className="b-bottom">
                <div className="b-b-row-one">{item.title_two}</div>

                <div className="b-b-row-two">{item.desc_two}</div>

                <div className="b-b-row-three">
                  <div className="r-t-left">
                    {item.hashtags.map((tag, index) => (
                      <div key={index}>{tag}</div>
                    ))}
                  </div>

                  <a href={item.url} target="_blank" rel="noreferrer">
                    <div className="r-t-right click no-select">
                      <span>Read more</span> <ArrowRight />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        <a href="https://getcova.com/blog" target="_blank" rel="noreferrer">
          <div className="view-all click no-select">View all</div>
        </a>
      </div>
    </div>
  );
};

export default BlogPosts;
