import { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Burgermenu2Icon } from "../icons/burgermenu2";
import { CovaLogo } from "../icons/covelogo";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";
import { showLifecheckLogo, signupLink } from "../../helpers/brand";
import "./header.scss";

export const HeadexComponent = () => {
  const [hidediv, sethidediv] = useState(true);

  const routes = [
    {
      name: "How it works",
      route: "/global/#how-it-works",
    },
    {
      name: "Reviews",
      route: "/global/#reviews",
    },
    {
      name: "FAQ",
      route: "/global/#faqs",
    },
  ];

  return (
    <div className="headercomp headercomp2">
      <div className="desktop ">
        <div>
          <NavLink to="#" className="covalogo">
          {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={100} />}
          </NavLink>
          <div className="alllinks">
            {routes.map((item) => (
              <HashLink smooth key={item.name} className="linksd" to={item.route}>
                {item.name}
              </HashLink>
            ))}
            <a href={signupLink} className="getstarted signup">
              Get started for free
            </a>
          </div>
        </div>
      </div>

      <div className="mobile ">
        <div className="inner-top">
          <div className="v-222">
            <NavLink to="/" className="covalogo">
              {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={100} />}
            </NavLink>
            <div>
              <span
                onClick={() => {
                  sethidediv(!hidediv);
                }}
              >
                <Burgermenu2Icon />
              </span>
            </div>
          </div>

          <div style={{ display: hidediv ? "none" : "flex" }} className="layer2">
            {routes.map((item) => (
              <HashLink
                onClick={() => {
                  sethidediv(!hidediv);
                }}
                smooth
                key={item.name}
                className="linksd"
                to={item.route}
              >
                {item.name}
              </HashLink>
            ))}

            <NavLink className="getstarted signup" to="/signup">
              Get Started
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
