import { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import HeaderMinimal from "../../components/header/headerMinimal";
import { ReactComponent as Asset } from "../../assets/svg/features-asset.svg";
import { ReactComponent as Secure } from "../../assets/svg/features-secure.svg";
import { ReactComponent as Stocks } from "../../assets/svg/features-stocks.svg";
import { ReactComponent as Vault } from "../../assets/svg/features-vault.svg";
import { Textinput } from "../../components/inputs/textinput";
import { TextinputwithLeftIcon } from "../../components/inputs/textinputwithlefticon";
import MainButton from "../../components/buttons/mainButton";
import { ClosedangerIcon } from "../../components/icons/closedanger";
import { MarksuccessIcon } from "../../components/icons/marksuccess";
import { LoaderContext } from "../../contexts/loader";
import ForYou from "../../components/foryou/foryou";
import { Testimonials } from "../../components/testimonials";
import { FooterComponent } from "../../components/footer";
import { GoogleIcon } from "../../components/icons/googleicon";
import "./index.scss";
import { orgName } from "../../helpers/brand";

const ADSLandingPage3 = () => {
  const [ispassword, setispassword] = useState<boolean>(true);
  const [, setShowLoader] = useContext(LoaderContext);
  const [adSource, setAdSource] = useState<string>("");
  const [percentOff, setPercentOff] = useState<string>("20");
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);
    const trafficSource = parseddata?.src;
    const customTrafficSource = parseddata?.cp;
    const off = parseddata?.ffo;

    if (off) {
      setPercentOff(window.atob(off));
    }

    if (customTrafficSource) {
      return localStorage.setItem("cp", customTrafficSource);
    }

    if (trafficSource) {
      switch (trafficSource) {
        case "bzg":
          localStorage.setItem("tsrc", "Benzinga");
          setAdSource("Benzinga Reader");
          break;
        case "ggl":
          localStorage.setItem("tsrc", "Google");
          break;
      }
    }

    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const changeinputField = () => {
    setispassword(!ispassword);
  };

  const onSubmit = (data: any) => {
    localStorage.setItem("signupmethod", "form");

    let requestdata = {
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      password: data.password,
    };

    setShowLoader(true);
    axios
      .post(`${process.env.REACT_APP_API}api/signup`, requestdata, {
        headers: {
          accept: "*/*",
        },
      })
      .then(() => {
        setShowLoader(false);
        navigate(`/verify-email/?email=${requestdata.email}`);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.response?.data?.message ?? "Something went wrong.");
      });
  };

  const googleSignup = () => {
    window.location.href = `${
      process.env.REACT_APP_API
    }oauth2/authorize/google?redirect_uri=${process.env.REACT_APP_CONVERSION?.trim()}`;
  };

  return (
    <div className="ads-page-container">
      <HeaderMinimal />
      <Helmet>
        <title>{orgName} | Track All Your Assets In One Place. Plan Your Estate</title>
        <meta
          name="description"
          content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
        />
        <meta
          property="og:title"
          content={`${orgName} | Track All Your Assets In One Place. Plan Your Estate`}
        />
        <meta
          property="og:description"
          content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
        />
        <meta property="og:url" content="https://www.getcova.com/" />
      </Helmet>
      <section className="banner21">
        <div className="banner-wrapper">
          <div className="b-w-left">
            <div className="b-w-l-row-one">Welcome{adSource && `, ${adSource}`}</div>

            <div className="b-w-l-row-two">
              <span>Organize</span> your wealth.<br/>
              <span>Protect</span> your loved ones.
            </div>

            <div className="b-w-l-row-three">
              {orgName} helps you organize all your assets in one place, tracks your net worth and
              securely notify your loved ones in the event of an eventuality.
            </div>

            <Features />
          </div>

          <div className="b-w-right">
            <div className="b-w-right-title">Let’s Get Started</div>

            <div className="google-btn-div">
              <button
                onClick={() => {
                  localStorage.setItem("signupmethod", "google");
                  googleSignup();
                }}
                className="google-btn"
              >
                <GoogleIcon />
                <span className="google-text">Sign Up with Google</span>
              </button>
            </div>

            <div className="or-div">
              <p className="or-text">Or</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-div">
                <Controller
                  name="email"
                  defaultValue={null}
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Textinput
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                      checked={value}
                      label="Email"
                      inputid="email"
                      type="email"
                      placeholder="name@email.com"
                      iserror={errors.email}
                      message={"Please provide a correct email address."}
                    />
                  )}
                />
              </div>
              <div className="input-div">
                <Controller
                  name="password"
                  defaultValue={null}
                  rules={{
                    validate: {
                      isUpper: (value) => /[A-Z]/.test(value),
                      is8Digit: (value) => value && value.length >= 8,
                      isNumber: (value) => /\d/.test(value),
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextinputwithLeftIcon
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                      checked={value}
                      type={ispassword ? "password" : "text"}
                      onclickicon={changeinputField}
                      label="Password"
                      inputid="password"
                      placeholder="8 characters, 1 upper case letter and 1 number."
                      iserror={errors.email}
                    />
                  )}
                />

                {errors.password ? (
                  <div className="">
                    <p className="err-deck-1">
                      {errors &&
                      errors.password &&
                      errors.password.types &&
                      errors.password.types.is8Digit ? (
                        <span className="mr-1">
                          <ClosedangerIcon />
                        </span>
                      ) : (
                        <span className="mr-1">
                          <MarksuccessIcon />
                        </span>
                      )}
                      <span>8 characters</span>
                    </p>
                    <p className="err-deck-1">
                      {errors &&
                      errors.password &&
                      errors.password.types &&
                      errors.password.types.isUpper ? (
                        <span className="mr-1">
                          <ClosedangerIcon />
                        </span>
                      ) : (
                        <span className="mr-1">
                          <MarksuccessIcon />
                        </span>
                      )}
                      <span>1 UPPER CASE letter</span>
                    </p>
                    <p className="err-deck-1">
                      {errors &&
                      errors.password &&
                      errors.password.types &&
                      errors.password.types.isNumber ? (
                        <span className="mr-1">
                          <ClosedangerIcon />
                        </span>
                      ) : (
                        <span className="mr-1">
                          <MarksuccessIcon />
                        </span>
                      )}
                      <span>1 number</span>
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="space-down">
                <MainButton type="primary" size="big" extraClasses="move-right">
                  <span className="text-p1">Sign up now to get {percentOff}% off</span>
                </MainButton>
              </div>
            </form>

            <div className="terms-info">
              By signing up, you agree to our{" "}
              <span
                className="click"
                onClick={() => {
                  navigate("/terms-and-condition");
                }}
              >
                Terms of Use
              </span>{" "}
              and{" "}
              <span
                className="click"
                onClick={() => {
                  navigate("/privacy");
                }}
              >
                Privacy Policy
              </span>
              . You also agree to receive updates, newsletters and offers from {orgName} Inc.
            </div>
          </div>

          <div className="f-wrapper">
            <Features />
          </div>
        </div>
      </section>

      <ForYou />
      <Testimonials setBlueBg />
      <FooterComponent visitor />
    </div>
  );
};

const Features = () => {
  const features = [
    {
      icon: Asset,
      text: "Track your real estate to bank accounts, crypto, and more, and see all holdings in one place.",
    },
    {
      icon: Secure,
      text: "Securely notify loved ones of your financial data in the event of an eventuality.",
    },
    {
      icon: Stocks,
      text: "Automatically track your net worth across multiple platforms",
    },
    {
      icon: Vault,
      text: "Save and manage crucial estate planning and finance documents with a vault.",
    },
  ];

  return (
    <div className="b-w-l-features">
      {features.map((item, index) => (
        <div className="f-item" key={index}>
          <div className="f-o-icon">
            <item.icon />
          </div>

          <div className="f-o-text">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default ADSLandingPage3;
