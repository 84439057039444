import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ReactPlayer from "react-player/youtube";
import Modal from "react-modal";
import { CovaLogo } from "../../components/icons/covelogo";
import { FooterComponent } from "../../components/footer";
import { HeadexComponent } from "../../components/header/headex2";
import { ReactComponent as BPeople } from "../../assets/svg/redesign/banner-people.svg";
import { ReactComponent as BShield } from "../../assets/svg/redesign/banner-shield.svg";
import { ReactComponent as BTracking } from "../../assets/svg/redesign/banner-tracking.svg";
import { ReactComponent as BRect } from "../../assets/svg/redesign/banner-rect.svg";
import { ReactComponent as BtnArrow } from "../../assets/svg/redesign/arrow-right.svg";
import { ReactComponent as Star } from "../../assets/svg/redesign/star.svg";
import { ReactComponent as ArrowLeftGrey } from "../../assets/svg/redesign/arrow-left-grey.svg";
import { ReactComponent as ArrowRightGrey } from "../../assets/svg/redesign/arrow-right-grey.svg";
import { ReactComponent as LightCancel } from "../../assets/svg/redesign/light-cancel.svg";
import { ReactComponent as LightEstate } from "../../assets/svg/redesign/light-estate.svg";
import { ReactComponent as LightHome } from "../../assets/svg/redesign/light-home.svg";
import { ReactComponent as LightPeople } from "../../assets/svg/redesign/light-people.svg";
import { ReactComponent as LightShield } from "../../assets/svg/redesign/light-shield.svg";
import { ReactComponent as LightSwitch } from "../../assets/svg/redesign/light-switch.svg";
import { ReactComponent as LightTrack } from "../../assets/svg/redesign/light-track.svg";
import { ReactComponent as LightCheck } from "../../assets/svg/redesign/light-check.svg";
import { ReactComponent as CheckBlue } from "../../assets/svg/redesign/check-blue.svg";
import { ReactComponent as F1 } from "../../assets/svg/redesign/features-one.svg";
import { ReactComponent as F2 } from "../../assets/svg/redesign/features-two.svg";
import { ReactComponent as F3 } from "../../assets/svg/redesign/features-three.svg";
import { ReactComponent as F4 } from "../../assets/svg/redesign/features-four.svg";
import { ReactComponent as F5 } from "../../assets/svg/redesign/features-five.svg";
import { ReactComponent as F6 } from "../../assets/svg/redesign/features-six.svg";
import { ReactComponent as F7 } from "../../assets/svg/redesign/features-seven.svg";
import { ReactComponent as F8 } from "../../assets/svg/redesign/features-eight.svg";
import { ReactComponent as Icon1 } from "../../assets/svg/redesign/Icon1.svg";
import { ReactComponent as Icon2 } from "../../assets/svg/redesign/Icon2.svg";
import { ReactComponent as Icon3 } from "../../assets/svg/redesign/Icon3.svg";
import User1 from "../../assets/svg/redesign/user/user1.png";
import Howitworks1 from "../../assets/img/track-everything.gif";
import Howitworks2 from "../../assets/img/emergencies.png";
import Howitworks3 from "../../assets/img/tracking.png";
import Howitworks4 from "../../assets/img/collaborate-with-others.png";
import PlayVideo from "../../assets/img/redesign/playvideo.png";
import Usage1 from "../../assets/img/redesign/usage1.png";
import Usage2 from "../../assets/img/redesign/usage2.png";
import Usage3 from "../../assets/img/redesign/usage3.png";
import Usage4 from "../../assets/img/redesign/usage4.png";
import Early1 from "../../assets/img/redesign/early1.png";
import Early2 from "../../assets/img/redesign/early2.png";
import Early3 from "../../assets/img/redesign/early3.png";
import BannerImage from "../../assets/img/redesign/banner-image.png";
import BottomImageLg from "../../assets/img/redesign/bottom-image-lg.png";
import BottomImageSm from "../../assets/img/redesign/bottom-image-sm.png";
import { FrequentlyAskedComponent } from "../../components/frequentlyasked";
import MediaFeature from "../../components/meadiafeature/mediafeature";
import Reviewcard from "../../components/reviewcard/reviewcard";
import { Helmet } from "react-helmet";
import "./index.scss";
import { orgName, showLifecheckLogo, signupLink } from "../../helpers/brand";
import { ReactComponent as LifecheckLogo } from "../../assets/svg/logo-lifecheck.svg";

const modalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    overflow: "hidden",
    transform: "translate(-50%, -40%)",
    zIndex: "305 !important",
  },
};

const ADSLandingPage = () => {
  const location = useLocation();
  const [tab, setTab] = useState<number>(0);
  const [tab2, setTab2] = useState<number>(0);
  const [tab3, setTab3] = useState<number>(0);
  const [howItWorksModal, setHowItWorksModal] = useState<boolean>(false);

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (!parseddata?.exed || !parseddata?.sec) {
      localStorage.removeItem("collab");
      localStorage.removeItem("sec");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userFeedback = [
    {
      name: "Emmanuel, San Jose, USA.",
      image: Early3,
      feedback:
        `I searched the internet for a solution to help me consolidate my investments online and give access to my family in case I'm not around, then ${orgName} came.`,
    },
    {
      name: "Seth Atham, Maryland, USA.",
      image: Early2,
      feedback:
        `From real estate assets to stocks, 401k, bank accounts, and brokerage accounts, ${orgName} gives me a clear view of all finances and the peace of mind that nothing will fall within the cracks in the event of any of life's emergencies.`,
    },
    {
      name: "Idiare, Lagos, Nigeria.",
      image: Early1,
      feedback:
        "I like how features are being driven by real life issues that occur when there’s no transparency in your personal financial position.",
    },
  ];

  return (
    <div className="landing-page-container">
      <Helmet>
        <title>Organize your investments in one place</title>
        <meta
          name="description"
          content="Track your investments and liabilities in real time and simplify your finances."
        />
        <meta property="og:title" content="Organize your investments in one place" />
        <meta
          property="og:description"
          content="Track your investments and liabilities in real time and simplify your finances."
        />
        <meta property="og:url" content="https://www.getcova.com/global" />
      </Helmet>

      <HeadexComponent />

      <Modal
        ariaHideApp={false}
        isOpen={howItWorksModal}
        onRequestClose={() => {
          setHowItWorksModal(false);
        }}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <div className="player-wrapper">
          <ReactPlayer playing width="100%" height="100%" url="https://youtu.be/VYSFtxb635Y" />{" "}
        </div>
      </Modal>

      <section className="l-p-banner">
        <div className="banner-inner">
          <div className="b-left">
            <div className="b-l-row-one large">
              The All-in-One Personal Finance App You Shouldn’t Live Without.
            </div>
            <div className="b-l-row-one small">
              <Star /> <Star /> <Star /> <Star /> <Star /> <span>100+ 5 Star Reviews Online</span>
            </div>

            <div className="b-l-row-two">
              <span>Simplify</span> your finances. Take back your <span>time.</span>
            </div>

            <div className="b-l-row-three">
              {orgName} makes managing your finances simple and easy. Track everything securely in real
              time and in one place and have peace of mind. Start your 14 day free trial.
            </div>

            <div className="b-l-row-four-group">
              <div className="r-f-item">
                <div className="r-f-icon">
                  <span>
                    <BTracking className="r-f-img" />
                  </span>
                  <BRect className="r-f-rect" />
                </div>{" "}
                Real-Time Tracking. Never miss a thing.
              </div>
              <div className="r-f-item">
                <div className="r-f-icon">
                  <span>
                    <BShield className="r-f-img" />
                  </span>
                  <BRect className="r-f-rect" />
                </div>
                100% Privacy Protection. Your data is fully encrypted.
              </div>
              <div className="r-f-item">
                <div className="r-f-icon">
                  <span>
                    <BPeople className="r-f-img" />
                  </span>
                  <BRect className="r-f-rect" />
                </div>
                Share access securely with your loved ones.
              </div>
            </div>

            <div className="btn-review-group">
              <a href={signupLink} className="blue-btn click">
                Get started for free
                <span>
                  <BtnArrow />
                </span>
              </a>
              <div className="r-f-desc">**No credit card required</div>
            </div>

            <Reviewcard
              desc="One of 100+ 5 Star Reviews Online"
              name="Emmanual, USA"
              review={`I searched the internet for a solution to help me consolidate my investments online and give access to my family in case I'm not around, then ${orgName} came.`}
              image={User1}
            />
          </div>

          <div className="b-right">
            <img src={BannerImage} alt="family" />
          </div>

          <div className="b-small-extra">
            <div className="e-row-one">
              <div className="r-f-item">
                <div className="r-f-icon">
                  <span>
                    <BTracking className="r-f-img" />
                  </span>
                  <BRect className="r-f-rect" />
                </div>{" "}
                Real-Time Tracking. <br /> Never miss a thing.
              </div>

              <div className="divider23"></div>

              <div className="r-f-item">
                <div className="r-f-icon">
                  <span>
                    <BPeople className="r-f-img" />
                  </span>
                  <BRect className="r-f-rect" />
                </div>
                Share access securely <br /> with your loved ones.
              </div>
            </div>

            <div className="btn-review-group">
              <a href={signupLink} className="blue-btn click">
                Get started for free
                <span>
                  <BtnArrow />
                </span>
              </a>
              <div className="r-f-desc">**No credit card required</div>
            </div>
          </div>
        </div>
      </section>

      <div className="media-feature-wrapper">
        <MediaFeature />
      </div>

      <section className="section-two">
        <div className="section-title292">
          Everything you need to <span>stay in control.</span>
        </div>

        <div className="s-t-tabs">
          {[
            "All-in-One Tracking",
            "Plan with Confidence",
            "User Data Encryption",
            "Collaborate Securely",
          ].map((item: string, index: number) => (
            <div
              key={item}
              className={`tab-item no-select click ${index === tab ? "is-selected" : ""}`}
              onClick={() => {
                setTab(index);
              }}
            >
              {item}
            </div>
          ))}
        </div>

        {tab === 0 && (
          <div className="s-t-content">
            <div className="c-left">
              <div className="l-title">
                Track everything you own <span>- in real-time.</span>
              </div>

              <div className="c-inner-wrapper">
                <div className="ctrl-btn no-select click disabled">
                  <ArrowLeftGrey />
                </div>

                <div className="c-main">
                  <div className="l-item">
                    <div className="l-i-icon">
                      <F1 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Track crypto and traditional assets together</div>

                      <div className="i-c-text">
                        From crypto assets to traditional assets like real estate and stocks, {orgName}
                        empowers you to track everything. Sync your bank accounts, crypto wallets
                        and investments to {orgName}. Get their balances in real-time and easily track
                        their growth and performance so you can always have peace of mind.
                      </div>
                    </div>
                  </div>

                  <div className="l-item">
                    <div className="l-i-icon">
                      <F2 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Borderless asset tracking</div>

                      <div className="i-c-text">
                        {orgName} supports over 11,000 financial institutions in North America, 25
                        Crypto-exchanges including Binance, Coinbase, Trust Wallets, over 8,000
                        crypto-tickers, over 150,000 stocks tickers from 65 exchanges globally.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ctrl-btn no-select click">
                  <ArrowRightGrey
                    onClick={() => {
                      setTab(1);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="c-right">
              <img src={Howitworks1} alt="track-everything" />
            </div>
          </div>
        )}

        {tab === 1 && (
          <div className="s-t-content">
            <div className="c-left">
              <div className="l-title">Be ready for all emergencies.</div>

              <div className="c-inner-wrapper">
                <div className="ctrl-btn no-select click">
                  <ArrowLeftGrey
                    onClick={() => {
                      setTab(0);
                    }}
                  />
                </div>

                <div className="c-main">
                  <div className="l-item">
                    <div className="l-i-icon">
                      <F3 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Set up beneficiaries and life checker</div>

                      <div className="i-c-text">
                        Create a beneficiary profile and set up a dead man switch to notify your
                        chosen beneficiary of your portfolio in the event of an emergency.
                      </div>
                    </div>
                  </div>

                  <div className="l-item">
                    <div className="l-i-icon">
                      <F4 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Manage crucial documents</div>

                      <div className="i-c-text">
                        {orgName} comes with a secured vault where you can keep crucial documents like
                        wills, trusts, photographs, videos and special notes for your loved ones.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ctrl-btn no-select click">
                  <ArrowRightGrey
                    onClick={() => {
                      setTab(2);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="c-right">
              <img src={Howitworks2} alt="track-everything" />
            </div>
          </div>
        )}

        {tab === 2 && (
          <div className="s-t-content">
            <div className="c-left">
              <div className="l-title">Secure wealth tracking.</div>

              <div className="c-inner-wrapper">
                <div className="ctrl-btn no-select click">
                  <ArrowLeftGrey
                    onClick={() => {
                      setTab(1);
                    }}
                  />
                </div>

                <div className="c-main">
                  <div className="l-item">
                    <div className="l-i-icon">
                      <F5 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Data-Encryption for your financial data</div>

                      <div className="i-c-text">
                        Your data on {orgName} is encrypted at rest, in-transit and in-use. All
                        information is stored using AES-256 encryption. Our internal processes
                        ensure no one at {orgName} gets to see your portfolio data.
                      </div>
                    </div>
                  </div>

                  <div className="l-item">
                    <div className="l-i-icon">
                      <F6 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">2FA authentication and vault PIN</div>

                      <div className="i-c-text">
                        We enforce 2FA to help ensure your data in secured with a unique PIN to your
                        {orgName} Vault. {orgName} also notifies you of every unusual login on your account.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ctrl-btn no-select click">
                  <ArrowRightGrey
                    onClick={() => {
                      setTab(3);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="c-right">
              <img src={Howitworks3} alt="track-everything" />
            </div>
          </div>
        )}

        {tab === 3 && (
          <div className="s-t-content">
            <div className="c-left">
              <div className="l-title">Collaborate with others on your portfolio.</div>

              <div className="c-inner-wrapper">
                <div className="ctrl-btn no-select click">
                  <ArrowLeftGrey
                    onClick={() => {
                      setTab(2);
                    }}
                  />
                </div>

                <div className="c-main">
                  <div className="l-item">
                    <div className="l-i-icon">
                      <F7 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Grant access to the important people</div>

                      <div className="i-c-text">
                        Grant your attorney, financial planners, advisors, and credit institutions
                        remote and secure access to your financial portfolio for credit, estate
                        planning, and other purposes.
                      </div>
                    </div>
                  </div>

                  <div className="l-item">
                    <div className="l-i-icon">
                      <F8 />
                    </div>
                    <div className="l-i-content">
                      <div className="i-c-title">Make smart investment decisions together</div>

                      <div className="i-c-text">
                        Securely collaborate with your spouse and the most important people in your
                        life. Share your net worth and asset data for loan and credit purposes.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ctrl-btn no-select click disabled">
                  <ArrowRightGrey />
                </div>
              </div>
            </div>

            <div className="c-right">
              <img src={Howitworks4} alt="track-everything" />
            </div>
          </div>
        )}

        <div className="dots">
          {[0, 1, 2, 3].map((dot: number) => (
            <div key={dot} className={`dot-item ${dot === tab ? "is-active" : ""}`} />
          ))}
        </div>

        <div className="btn-review-group">
          <a href={signupLink} className="blue-btn click">
            Get started for free
            <span>
              <BtnArrow />
            </span>
          </a>
          <div className="r-f-desc">
            <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
          </div>
        </div>
      </section>

      <section className="section-three">
        <div className="s-t-inner">
          <div className="section-title292">
            What makes {orgName} <span>the right choice.</span>
          </div>

          <div className="s-t-main">
            <div className="s-t-col one">
              <div className="col-title">Benefits</div>

              <div className="col-item">
                <LightTrack className="c-i-icon" />
                Real-Time Tracking
              </div>
              <div className="col-item">
                <LightShield className="c-i-icon" />
                User Data Encryption
              </div>
              <div className="col-item">
                <LightEstate className="c-i-icon" />
                Unlimited Asset Sync
              </div>
              <div className="col-item">
                <LightPeople className="c-i-icon" />
                Shared-Account Access
              </div>
              <div className="col-item">
                <LightHome className="c-i-icon" />
                Beneficiary Tools
              </div>
              <div className="col-item">
                <LightSwitch className="c-i-icon" />
                Deadman Switch Tool
              </div>
            </div>

            <div className="s-t-col two">
              <div className="col-title">
                {!showLifecheckLogo ? <CovaLogo /> : <LifecheckLogo width={150} height={40} />}
              </div>

              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <CheckBlue />
              </div>
            </div>

            <div className="s-t-col three">
              <div className="col-title">Other Apps</div>

              <div className="col-item">
                <CheckBlue />
              </div>
              <div className="col-item">
                <LightCancel />
              </div>
              <div className="col-item">
                <LightCancel />
              </div>
              <div className="col-item">
                <LightCancel />
              </div>
              <div className="col-item">
                <LightCancel />
              </div>
              <div className="col-item">
                <LightCancel />
              </div>
            </div>
          </div>

          <div className="btn-review-group">
            <a href={signupLink} className="blue-btn click">
              Get started for free
              <span>
                <BtnArrow />
              </span>
            </a>
            <div className="r-f-desc">
              <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
            </div>
          </div>
        </div>
      </section>

      <section className="section-four" id="how-it-works">
        <div className="s-f-inner">
          <div className="s-f-left">
            <div className="f-row-one">Learn how {orgName} works</div>

            <div className="f-row-two">
              Create your free account today, get setup in minutes, and discover what {orgName} can do
              for you.
            </div>

            <div className="btn-review-group">
              <a href={signupLink} className="blue-btn click">
                Get started for free
                <span>
                  <BtnArrow />
                </span>
              </a>
              <div className="r-f-desc light">
                <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
              </div>
            </div>
          </div>

          <div className="s-f-right">
            <img
              className="click"
              onClick={() => {
                setHowItWorksModal(true);
              }}
              src={PlayVideo}
              alt="video-player"
            />
          </div>

          <div className="btn-review-group sm-only">
            <a href={signupLink} className="blue-btn click">
              Get started for free
              <span>
                <BtnArrow />
              </span>
            </a>
            <div className="r-f-desc light">
              <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
            </div>
          </div>
        </div>
        <div className="s-f-curve"></div>
      </section>

      <section className="section-five">
        <div className="section-title292">
          <span>How people are using {orgName}</span>
        </div>

        <div className="section-desc292">
          Here’s some of the top ways people are taking advantage of {orgName}’s innovative tools to
          build a better future for themselves and their loved ones.
        </div>

        <div className="s-main-wrapper">
          <div className={`ctrl-btn no-select click ${tab2 === 0 ? "disabled" : ""}`}>
            <ArrowLeftGrey
              onClick={() => {
                if (tab2 > 0) {
                  setTab2(tab2 - 1);
                }
              }}
            />
          </div>
          <div className="s-five-main">
            <div className={`s-f-item ${tab2 === 0 ? "visible" : ""}`}>
              <div className="i-image23">
                <img src={Usage1} alt="family-with-laptop" />
              </div>

              <div className="i-title23">Build Investment Portfolio</div>
              <div className="i-desc23">
                Grow your investments with confidence with powerful insights and track everything in
                real-time.
              </div>
            </div>

            <div className={`s-f-item ${tab2 === 1 ? "visible" : ""}`}>
              <div className="i-image23">
                <img src={Usage2} alt="family-with-laptop" />
              </div>

              <div className="i-title23">Organize Personal Finances</div>
              <div className="i-desc23">
                {orgName} makes household account management simple. Stay on top of your finances and get
                back more of your life.
              </div>
            </div>

            <div className={`s-f-item ${tab2 === 2 ? "visible" : ""}`}>
              <div className="i-image23">
                <img src={Usage3} alt="family-with-laptop" />
              </div>

              <div className="i-title23">Protect Loved Ones and Plan Ahead</div>
              <div className="i-desc23">
                Have peace of mind knowing your assets will be transferred automatically to your
                family if anything happens to you.
              </div>
            </div>

            <div className={`s-f-item ${tab2 === 3 ? "visible" : ""}`}>
              <div className="i-image23">
                <img src={Usage4} alt="family-with-laptop" />
              </div>

              <div className="i-title23">Collaborate on Investment Opportuntites</div>
              <div className="i-desc23">
                Grant your friend, attorney, and credit institutions secure remote access to your
                portfolio for credit, estate planning, and other purposes.
              </div>
            </div>
          </div>

          <div className={`ctrl-btn no-select click ${tab2 === 3 ? "disabled" : ""}`}>
            <ArrowRightGrey
              onClick={() => {
                if (tab2 < 3) {
                  setTab2(tab2 + 1);
                }
              }}
            />
          </div>
        </div>

        <div className="dots">
          {[0, 1, 2, 3].map((dot: number) => (
            <div key={dot} className={`dot-item ${dot === tab2 ? "is-active" : ""}`} />
          ))}
        </div>
      </section>

      <section className="section-six" id="reviews">
        <div className="s-s-wrapper">
          <div className="section-title292">
            <span>What Our Early Users Are Saying</span>

            <div className="s-s-main-wrapper">
              <div className={`ctrl-btn no-select click ${tab3 === 0 ? "disabled" : ""}`}>
                <ArrowLeftGrey
                  onClick={() => {
                    if (tab3 > 0) {
                      setTab3(tab3 - 1);
                    }
                  }}
                />
              </div>

              <div className="s-s-main">
                {userFeedback.map((item, index) => (
                  <div key={index} className={`card-container ${tab3 === index ? "visible" : ""}`}>
                    <Reviewcard
                      desc=""
                      name={item.name}
                      review={item.feedback}
                      image={item.image}
                    />
                  </div>
                ))}
              </div>

              <div className={`ctrl-btn no-select click ${tab3 === 2 ? "disabled" : ""}`}>
                <ArrowRightGrey
                  onClick={() => {
                    if (tab3 < 2) {
                      setTab3(tab3 + 1);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="dots">
        {[0, 1, 2].map((dot: number) => (
          <div key={dot} className={`dot-item ${dot === tab3 ? "is-active" : ""}`} />
        ))}
      </div>

      <div className="s-six-action">
        <div className="btn-review-group">
          <a href={signupLink} className="blue-btn click">
            Get started for free
            <span>
              <BtnArrow />
            </span>
          </a>
          <div className="r-f-desc">
            <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
          </div>
        </div>
      </div>

      <FrequentlyAskedComponent />

      <section className="section-eight">
        <div className="s-e-wrapper">
          <div className="s-e-item">
            <div className="item-row-one">
              <Icon1 />
            </div>

            <div className="item-row-two">Stay Organized & In Control.</div>

            <div className="item-row-three">
              Plan for the long term with {orgName}. Organize your assets, track your net worth, build
              your digital vault. Take control of your wealth today.
            </div>
          </div>

          <div className="divider" />

          <div className="s-e-item">
            <div className="item-row-one">
              <Icon2 />
            </div>

            <div className="item-row-two">Stay Safe & Secure.</div>

            <div className="item-row-three">
              Your privacy comes first. Your data is fully encrypted. We take privacy seriously.
            </div>
          </div>

          <div className="divider" />

          <div className="s-e-item">
            <div className="item-row-one">
              <Icon3 />
            </div>

            <div className="item-row-two">Save Time & Stress.</div>

            <div className="item-row-three">
              Life happens. Setup your beneficiaries and life-checker on {orgName} and never worry about
              what happens in the event of an eventuality.
            </div>
          </div>
        </div>
      </section>

      <section className="section-nine">
        <div className="section-title292">
          <span>Get your time back</span> with {orgName}.
        </div>

        <div className="section-desc292">
          We get it. Managing your finances can be complicated, but it doesn’t have to be. With
          {orgName}, it’s finally time to say goodbye to stress and say hello building a better life for
          you and those you love.
        </div>

        <div className="s-n-image">
          <img src={BottomImageLg} className="s-n-i-lg" alt="cova-connecting-users" />
          <img src={BottomImageSm} className="s-n-i-sm" alt="cova-connecting-apps" />
        </div>

        <div className="btn-review-group">
          <a href={signupLink} className="blue-btn click">
            Get started for free
            <span>
              <BtnArrow />
            </span>
          </a>
          <div className="r-f-desc">
            <Star /> <Star /> <Star /> <Star /> <Star /> Over 100+ 5 Star Reviews Online
          </div>
        </div>

        <div className="s-n-group">
          <div className="s-n-item">
            <LightTrack className="s-n-icon rs" /> Track Everything In Real Time
          </div>

          <div className="s-n-item">
            <LightHome className="s-n-icon rs" /> Share Access with Beneficiaries
          </div>

          <div className="s-n-item">
            <LightShield className="s-n-icon" /> 100% Privacy Protection.
          </div>

          <div className="s-n-item sm-only">
            <LightCheck className="s-n-icon" /> No Credit Card Required.
          </div>
        </div>

        <div className="text2376">**No credit card required</div>
      </section>

      <FooterComponent />
    </div>
  );
};

export default ADSLandingPage;
