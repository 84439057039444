import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { FooterComponent } from "../../components/footer";
import { HeadexComponent } from "../../components/header/headex";
import BannerImage from "../../assets/img/banner-image.png";
import Howitworks1 from "../../assets/img/track-everything.gif";
import Howitworks2 from "../../assets/img/emergencies.png";
import Howitworks3 from "../../assets/img/tracking.png";
import Howitworks4 from "../../assets/img/collaborate-with-others.png";
import { ReactComponent as Shield } from "../../assets/svg/shield.svg";
import { ReactComponent as Statistics } from "../../assets/svg/statistics.svg";
import { ReactComponent as Relieved } from "../../assets/svg/relieved.svg";
import { ReactComponent as Bank } from "../../assets/svg/bank.svg";
import { ReactComponent as Diamond } from "../../assets/svg/asset-diamond-big.svg";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { ReactComponent as FileText } from "../../assets/svg/file-text.svg";
import { ReactComponent as Shield2 } from "../../assets/svg/shield-white.svg";
import { ReactComponent as Password } from "../../assets/svg/Orion_password.svg";
import { ReactComponent as BankBubble } from "../../assets/svg/bank-bubble.svg";
import { ReactComponent as BitcoinBubble } from "../../assets/svg/bitcoin-bubble.svg";
import { ReactComponent as HomeBubble } from "../../assets/svg/home-bubble.svg";
import { ReactComponent as Access } from "../../assets/svg/access-icon.svg";
import { ReactComponent as Hands } from "../../assets/svg/hands-icon.svg";
import { ComingSoonComponent } from "../../components/comingsoon";
import { FrequentlyAskedComponent } from "../../components/frequentlyasked";
import { Testimonials } from "../../components/testimonials";
import MediaFeature from "../../components/meadiafeature/mediafeature";
import BlogPosts from "../../components/blogposts/blogposts";
import { ReactComponent as CardVect } from "../../assets/svg/cova-card-vect.svg";
import ForYou from "../../components/foryou/foryou";
import "./index.scss";
import { orgName, signupLink } from "../../helpers/brand";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    let parseddata: any = queryString.parse(location.search);

    if (!parseddata?.exed || !parseddata?.sec) {
      localStorage.removeItem("collab");
      localStorage.removeItem("sec");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <HeadexComponent />

      <div className="landingpage">
        <Helmet>
          <title>{orgName} | Track All Your Assets In One Place. Plan Your Estate</title>
          <meta
            name="description"
            content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
          />
          <meta
            property="og:title"
            content={`${orgName} | Track All Your Assets In One Place. Plan Your Estate`}
          />
          <meta
            property="og:description"
            content={`Organize your investments, bank accounts, stocks, digital & traditional assets with ${orgName}. Decide who gets the information in case of an emergency.`}
          />
          <meta property="og:url" content="https://www.getcova.com/" />
        </Helmet>

        <div className="familybackground">
          <div className="banner-wrapper">
            <div
              className="showmesomethings"
              data-aos="zoom-out"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <h1 className="organiset">
                <span>Organize</span> your wealth.
                <br /> <span>Protect</span> your loved ones.
              </h1>
              <br />
              <p className="organisec">
                {orgName} helps you organize all your assets in one place, tracks your net worth and
                securely notify your loved ones in the event of an eventuality.
              </p>
              <br />
              <a href={signupLink} className="getstarted">
                Get started for free
              </a>
              <div className="no-card">*No credit card required</div>
            </div>
            <div className="sided1">
              <div className="family-image">
                <BankBubble className="vect121 wej" />
                <BitcoinBubble className="vect121 ri2" />
                <HomeBubble className="vect121 dsg" />
                <div className="vect121 netwo">
                  <div className="netwo-t">Your Networth</div>
                  <div className="netwo-p">$420,000</div>
                </div>
                <img src={BannerImage} alt="" height={470} width={458} />
              </div>
            </div>
          </div>
        </div>

        <section className="section-2">
          <div className="section-2-wrapper">
            <div className="section-2-inner-wrapper">
              <div className="card22">
                <div className="card22-wrapper" data-aos="zoom-out-right">
                  <div className="img">
                    <Relieved />
                  </div>
                  <div className="title22">Peace of mind</div>
                  <div className="desc22">
                    Life happens. Setup your beneficiaries and life-checker. Never worry about what
                    happens in the event of an eventuality.
                  </div>
                </div>

                <CardVect className="card-vect" />
              </div>

              <div className="card22">
                <div className="card22-wrapper" data-aos="zoom-out">
                  <div className="img">
                    <Statistics />
                  </div>
                  <div className="title22">Limitless Portfolio Tracking</div>
                  <div className="desc22">
                    From alternative assets, digital assets to traditional assets like real estate
                    and stocks, {orgName} empowers you to track everything.
                  </div>
                </div>

                <CardVect className="card-vect two" />
              </div>

              <div className="card22">
                <div className="card22-wrapper" data-aos="zoom-out-left">
                  <div className="img">
                    <Shield />
                  </div>
                  <div className="title22">Your privacy comes first</div>
                  <div className="desc22">
                    Your data is fully encrypted. We take your privacy seriously. No one at {orgName}
                    sees your portfolio data.
                  </div>
                </div>

                <CardVect className="card-vect three" />
              </div>
            </div>
          </div>
        </section>

        <ForYou />

        <div id="how-it-works" className="how-it-works">
          <div className="image-how-it">
            <div className="image-how-it-img-div left-img6 dol-4">
              <div className="cf3">
                <img
                  height="10"
                  className="top"
                  src={Howitworks1}
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              </div>
            </div>
            <div className="section-text-content">
              <div className="title111" data-aos="fade-up" data-aos-duration="1000">
                Track everything you own - in realtime
              </div>
              <div className="top111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Diamond />
                  Track digital and traditional assets
                </div>
                <div>
                  Sync your bank, wallets and investments to {orgName}. Get their balances in real-time
                  and easily track their growth and performance.
                </div>
              </div>
              <div className="bottom111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Bank /> Borderless asset tracking
                </div>
                <div>
                  {orgName} supports over 11,000 financial institutions in North America, 25
                  Crypto-exchanges including Binance, Coinbase, Trust Wallets, over 8,000
                  crypto-tickers, over 150,000 stocks tickers from 65 exchanges globally.
                </div>
              </div>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="section-text-content">
              <div className="title111" data-aos="fade-up" data-aos-duration="1000">
                Be ready for all emergencies
              </div>
              <div className="top111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <User /> Set up beneficiaries and life checker
                </div>
                <div>
                  Create a beneficiary profile and set up a dead man switch to notify your chosen
                  beneficiary of your portfolio in the event of an emergency.
                </div>
              </div>
              <div className="bottom111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <FileText /> Manage crucial documents
                </div>
                <div>
                  {orgName} comes with a secured vault where you can keep crucial documents like wills,
                  trusts, photographs, videos and special notes for your loved ones.
                </div>
              </div>
            </div>
            <div className="cf3 image-how-it-img-div right-img6">
              <img
                src={Howitworks2}
                alt="How it works"
                className="how-it-works-image"
                data-aos="fade-up"
                data-aos-duration="1000"
              />
            </div>
          </div>

          <div className="image-how-it">
            <div className="image-how-it-img-div left-img6">
              <img
                src={Howitworks3}
                alt="How it works"
                className="how-it-works-image hi3"
                data-aos="fade-up"
                data-aos-duration="1000"
              />
            </div>
            <div className="section-text-content">
              <div className="title111" data-aos="fade-up" data-aos-duration="1000">
                Secure wealth tracking
              </div>
              <div className="top111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Shield2 />
                  Data Encryption for your financial data
                </div>
                <div>
                  Your data on {orgName} is encrypted at rest, in transit and in use. All information is
                  stored using AES-256 encryption. Our internal processes ensure no one at {orgName} gets
                  to see your portfolio data.
                </div>
              </div>
              <div className="bottom111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Password /> 2FA authentication and vault PIN
                </div>
                <div>
                  We enforce 2FA to help ensure your data in secured with a unique PIN to your {orgName}
                  Vault. {orgName} also notifies you of every unusual login on your account.
                </div>
              </div>
            </div>
          </div>

          <div className="image-how-it depictreverse">
            <div className="section-text-content">
              <div className="title111" data-aos="fade-up" data-aos-duration="1000">
                Collaborate with others on your portfolio
              </div>
              <div className="top111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Access /> Grant access to the important people
                </div>
                <div>
                  Grant your attorney, financial planners, advisors, and credit institutions remote
                  and secure access to your financial portfolio for credit, estate planning, and
                  other purposes.
                </div>
              </div>
              <div className="bottom111" data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <Hands />
                  Make smart investment decisions together
                </div>
                <div>
                  Securely collaborate with your spouse and the most important people in your life.
                  Share your net worth and asset data for loan and credit purposes.
                </div>
              </div>
            </div>
            <div className="cf3 image-how-it-img-div right-img6">
              <img
                src={Howitworks4}
                alt="How it works"
                className="how-it-works-image"
                data-aos="fade-up"
                data-aos-duration="1000"
              />
            </div>
          </div>
        </div>

        <MediaFeature />
        <Testimonials />
        <ComingSoonComponent />
        <FrequentlyAskedComponent />
        <BlogPosts />
        <FooterComponent />
      </div>
    </div>
  );
};

export default LandingPage;
