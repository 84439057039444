import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { HeadexComponent } from "../../components/header/headex";
import { ReactComponent as Curve } from "../../assets/svg/curve.svg";
import { ReactComponent as Stocks } from "../../assets/svg/stocks-light.svg";
import { ReactComponent as Bank } from "../../assets/svg/bank-light.svg";
import { ReactComponent as Relieved } from "../../assets/svg/relieved-light.svg";
import ClientsImage from "../../assets/img/clients-list.png";
import Vect211 from "../../assets/img/vect211.png";
import Vect221 from "../../assets/img/vect221.png";
import Dashboard from "../../assets/img/dashboard.png";
import AppFace from "../../assets/img/dashboardmockup2.png";
import { FooterComponent } from "../../components/footer";
import CollectBusinessDetails from "../../components/collectBusinessDetails/collectBusinessDetails";
import "./business.scss";
import { orgName } from "../../helpers/brand";

const Business = () => {
  const [submitBusinessDetailsModal, setSubmitBusinessDetailsModal] = useState<boolean>(false);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  const modalStyles = {
    content: {
      top: "30%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0px",
      overflow: "hidden",
      transform: "translate(-50%, -30%)",
      zIndex: "305 !important",
      backgroundColor: "white",
    },
  };

  return (
    <div className={submitBusinessDetailsModal ? "no-overflow" : ""}>
      <HeadexComponent />

      <Helmet>
        <title>{orgName} for Business</title>
        <meta name="description" content="" />
        <meta property="og:title" content={`${orgName} for Business`} />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://www.getcova.com/business" />
      </Helmet>

      <Modal
        ariaHideApp={false}
        isOpen={submitBusinessDetailsModal}
        onRequestClose={() => {
          setSubmitBusinessDetailsModal(false);
        }}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <CollectBusinessDetails
          closeModal={() => {
            setSubmitBusinessDetailsModal(false);
          }}
        />
      </Modal>

      <div className="business-page-container">
        <section className="b-p-banner">
          <div className="b-row-one">
            All-in-one portfolio tracker for{" "}
            <div>
              <Curve className="curve12" />
              Advisors
            </div>
          </div>

          <div className="b-row-two">
            <div
              className="b-p-btn click no-select"
              onClick={() => {
                setSubmitBusinessDetailsModal(true);
              }}
            >
              Get Access
            </div>
          </div>

          <div className="b-row-three">
            <img src={Dashboard} alt="" />
          </div>
        </section>

        <section className="b-features">
          <div className="b-f-inner">
            <div className="b-f-item">
              <Stocks className="f-icon" />
              <div className="f-title">Real-time tracking tools</div>
              <div className="f-desc">
                Offer real-time asset and portfolio management tools for your millennial and GenZ
                clients.
              </div>
            </div>

            <div className="b-f-item">
              <Bank className="f-icon" />
              <div className="f-title">Sync financial institutions</div>
              <div className="f-desc">
                Sync bank accounts, crypto wallets, brokerage apps, real estate, stocks and more.
              </div>
            </div>

            <div className="b-f-item">
              <Relieved className="f-icon" />
              <div className="f-title">Empower your clients</div>
              <div className="f-desc">
                Give your clients a mobile tool that makes it easy to track and build wealth.
              </div>
            </div>
          </div>
        </section>

        <section className="b-benefits">
          <div className="b-b-inner">
            <div className="c-b-left">
              <div className="b-l-item">
                <div className="l-i-title">
                  Reduce advisory paperwork <Curve className="curve12" />
                </div>

                <div className="l-i-desc">
                  Get your clients to share the portfolio with you in real-time with the needed
                  Excel sheets and account statements back and forth.
                </div>
              </div>

              <div className="b-l-item">
                <div className="l-i-title">
                  Digital and Crypto first <Curve className="curve12 dc" />
                </div>

                <div className="l-i-desc">
                  {orgName} lets your clients sync and tracks their cryptocurrency holdings across 31
                  crypto wallets,, including Coinbase, Binance, Kucoin, TrustWallet, etc.
                </div>
              </div>

              <div className="b-l-item">
                <div className="l-i-title">
                  Go digital. Go mobile. <Curve className="curve12 dm" />
                </div>

                <div className="l-i-desc">
                  Your young, millennial, and Genz tech-savvy clients make investment decisions on
                  their phones, give them a tool that makes tracking their assets easy.
                </div>
              </div>
            </div>

            <div className="c-b-right">
              <div className="blue-bg"></div>
              <div className="b-r-img-container">
                <img src={ClientsImage} alt="" />
                <img src={Vect221} className="vect24 a" alt="" />
                <img src={Vect211} className="vect24 b" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="request-access">
          <div className="r-a-inner">
            <div className="r-a-left">
              <div className="a-l-row-one">
                The perfect tool to work with Millennials, Genz and Crypto-savvy clients.
              </div>

              <div className="a-l-row-two">
                See what your clients see in their investment apps and portfolios in real-time.
              </div>

              <div
                className="b-p-btn light click no-select"
                onClick={() => {
                  setSubmitBusinessDetailsModal(true);
                }}
              >
                Request Access
              </div>
            </div>

            <div className="r-a-right">
              <img src={AppFace} alt="app-ui" />
            </div>
          </div>
        </section>

        {!submitBusinessDetailsModal && <FooterComponent />}
      </div>
    </div>
  );
};

export default Business;
